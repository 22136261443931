import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import AlertsSlider from "../components/alertsslider"
import SEO from "../components/seo"

class pageTemplateEvent extends Component {
  render() {
    var moment = require('moment'); // require

    const currentPage = this.props.data.nodeEvent;
    const upcomingEvents = this.props.data.allNodeEvent;
    const landingPage = this.props.data.allNodeLandingPage
    const alerts = this.props.data.allNodeAlert
    const meta = currentPage.relationships.field_meta_data
    var eventStart = currentPage.field_start_date.split("-04:00")[0];
    var eventEnd = currentPage.field_end_date.split("-04:00")[0];
    var startDate = moment(eventStart).format("dddd, MMMM D");
    var endDate = moment(eventEnd).format("dddd, MMMM D");
    var startTime = moment(eventStart).format("hh:mm A");
    var endTime = moment(eventEnd).format("hh:mm A");
    var calendarStart = moment(eventStart).format("YYYYMMDDTHHmmss");
    calendarStart += 'Z';
    var calendarEnd = moment(eventEnd).format("YYYYMMDDTHHmmss");
    calendarEnd += 'Z';
    var calendarTitle = encodeURIComponent(currentPage.title);
    var ctz = 'America/New_York';
    var calendarLink = 'https://calendar.google.com/calendar/r/eventedit?text=' + calendarTitle + '&dates=' + calendarStart + '/' + calendarEnd + '&ctz=' + ctz;
    var address
    var directionFull
    var groupIdTemp
    var landingPageEvent
    currentPage.relationships.group_content__departments_group_node_event && currentPage.relationships.group_content__departments_group_node_event.length > 0 ? (
        groupIdTemp = currentPage.relationships.group_content__departments_group_node_event[0].relationships.gid.drupal_internal__id
    ) : (
      groupIdTemp = null
    )
    landingPageEvent = landingPage.edges.filter(
      item => item.node.relationships.group_content__group_content_type_20b7e70e3cc2b !== null && item.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id === groupIdTemp
    )

    var landingPageRel = []
    var landningGroup
    landingPage.edges && landingPage.edges.map((page,key) => (
      page.node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
        landningGroup = page.node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id,
        landingPageRel[landningGroup] = [],
        landingPageRel[landningGroup]['title'] = page.node.relationships.field_hero.field_title_html.value,
        landingPageRel[landningGroup]['path'] = page.node.path.alias

      ) : (
        null
      )))


    if(currentPage.field_address_line_2 !== null) {
      address = currentPage.field_address_line_1 + ', ' + currentPage.field_address_line_2;
    } else {
      address = currentPage.field_address_line_1;
    }
    if(address !== null) {
      var directionsAddress = address.replace(/ /g, '+');
      directionFull = 'https://www.google.com/maps/dir/Current+Location/' + directionsAddress
    }
    return (
        <Layout >
        <SEO
            title={currentPage.title}
            contentBody={currentPage.body}
            metaData={meta}
            locationOrigin={this.props.location.origin + currentPage.path.alias}
            domen={this.props.location.origin}
        />

          <div className="single-event">
            <section className="uk-padding-remove-top">
              {landingPageEvent.length > 0 && landingPageEvent[0].node.relationships.group_content__group_content_type_20b7e70e3cc2b ? (
                <AlertsSlider allAlerts={alerts.edges} groupId={landingPageEvent[0].node.relationships.group_content__group_content_type_20b7e70e3cc2b[0].relationships.gid.drupal_internal__id} globalPage={false} />
              ) : (
                <AlertsSlider allAlerts={alerts.edges} groupId={null} globalPage={true} />
              )}
              <div className="container uk-margin-medium-top">
                <div className="event-heading">
                  {startDate === endDate ? (
                    <div className="single-event-date" dangerouslySetInnerHTML={{ __html: startDate }} />
                  ) : (
                    <div className="single-event-date">{startDate} - {endDate}</div>
                  )}
                  {landingPageEvent.length >= 1 && landingPageEvent[0].node.relationships.field_hero ? (
                    <div className="event-content-department"><Link to={landingPageEvent[0].node.path.alias + '/events'} >{landingPageEvent[0].node.relationships.field_hero.field_title_html.value}</Link></div>
                  ) : (
                    <div className="event-content-department"><a href="/events">County Events</a></div>
                  )}
                </div>


                <div className="single-event-title">
                  <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />

                </div>
                <div className="uk-grid" data-uk-grid>
                  <div className="uk-width-1-3@m">
                    <div className="single-event-time">
                      {startTime} - {endTime}
                      <a className="add" href={calendarLink} target="_blank" rel="noreferrer">Add to Calendar</a>
                    </div>

                    {currentPage.field_virtual_event !== true ? (
                      <div className="single-event-location">
                        {address}
                        {directionFull ? (
                          <a className="add" href={directionFull} target="_blank" rel="noreferrer">Get Direction</a>
                        ) : (
                          null
                        )}
                      </div>
                    ) : (
                      <div className="single-event-location">
                        Virtual Event
                      </div>
                    )}

                  </div>


                  <div className="single-event-map uk-width-2-3@m">
                    {address && address !== null && currentPage.field_virtual_event !== true ? (

                      <iframe title="map" src={"https://www.arcgis.com/apps/Embed/index.html?webmap=42d89f84ec4b4627ab2b2d17075e9873&search=true&searchextent=true&find=" + encodeURIComponent(address)} />

                    ) : (
                      null
                    )}

                  </div>

                  </div>
                {currentPage.body && currentPage.body.processed !== '' ? (
                  <div className="single-event-content" dangerouslySetInnerHTML={{ __html: currentPage.body.processed }}/>
                ) : (
                  null
                )}
                <a className="back-to" href="/events">All Events</a>
              </div>
            </section>

            <section className="upcoming-events">
              <div className="container">
                <h2>Upcoming Events</h2>
                <div className="uk-grid-small uk-flex uk-child-width-1-2@m uk-child-width-1-4@l uk-grid-match" data-uk-grid>
                  {upcomingEvents.nodes.map((event, index) => (
                    <div key={index}>
                      <div className=" uk-card uk-flex uk-flex-column events-cards">
                        <div className="uk-card-header">
                          {moment(event.field_start_date).format("dddd, MMMM D")}
                        </div>
                        <div className="uk-card-body">
                          <Link to={event.path.alias}><h3 className="h5">{event.title}</h3></Link>
                        </div>
                        <div className="uk-card-footer">
                          {event.field_virtual_event === true ? (
                            <div className="location">Virtual Event</div>
                          ) : (
                            event.field_address_line_2 != null && event.field_address_line_1 != null ? (
                              <React.Fragment>
                                <div className="time">{moment(event.field_start_date).format("hh:mm A")} - {moment(event.field_end_date).format("hh:mm A")}</div>
                                <div className="location">{event.field_address_line_1 + ", " + event.field_address_line_2}</div>
                              </React.Fragment>
                            ) : (
                              event.field_address_line_1 != null ? (
                                <React.Fragment>
                                  <div className="time">{moment(event.field_start_date).format("hh:mm A")} - {moment(event.field_end_date).format("hh:mm A")}</div>
                                  <div className="location">{event.field_address_line_1}</div>
                                </React.Fragment>
                              ) : (
                                null
                              )
                            )
                          )}

                          {
                            event.relationships.group_content__departments_group_node_event && event.relationships.group_content__departments_group_node_event.length > 0 ? (
                              event.relationships.group_content__departments_group_node_event.map((group,key) => (
                                landingPageRel[group.relationships.gid.drupal_internal__id] !== undefined ? (
                                  <Link className="landing-page-link" key={"grouplink"+key} to={landingPageRel[group.relationships.gid.drupal_internal__id]['path'] + '/events'}>{landingPageRel[group.relationships.gid.drupal_internal__id]['title']}<br /></Link>
                                ) : (
                                  <Link className="landing-page-link" to="/events">County Events</Link>
                                )
                              ))
                            ) : (
                              <Link className="landing-page-link" to="/events">County Events</Link>
                            )
                          }

                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>


        </Layout>
    )
  }
}

export default pageTemplateEvent

export const pageQuery = graphql`
  query($id: String!, $date: Date) {
    nodeEvent(id: {eq: $id}) {
      title
      id
      field_virtual_event
      body {
        value
        processed
      }
      path {
        alias
      }

      field_end_date
      field_start_date
      field_address_line_1
      field_address_line_2
      relationships {
        group_content__departments_group_node_event {
          relationships {
            gid {
              drupal_internal__id
            }
          }
        }
        field_meta_data {
          ...paragraph__meta_dataFragment
        }
      }
    }

    allNodeLandingPage {
      edges {
        ...node__landing_pageEdgeFragment
      }
    }


    allNodeAlert(sort: {fields: field_weight, order: ASC}) {
      edges {
       ...node__alertEdgeFragment
      }
    }

    allNodeEvent(sort: {fields: field_start_date}, limit: 4, filter: {field_start_date: {gt: $date},id: {ne: $id}}) {
      nodes {
        title
        field_end_date
        field_start_date
        field_address_line_1
        field_address_line_2
        field_virtual_event
        relationships {
          group_content__departments_group_node_event {
            relationships {
              gid {
                drupal_internal__id
              }
            }
          }
        }
        path {
          alias
        }
      }
    }
  }
`
